<template>
  <v-container fluid class="page-content">
    <v-data-table
          :headers="headers"
          :items="hydrowebs"
          class="elevation-1"
          :items-per-page="10"
          :search="filter"
        >
          <template v-slot:item.comBox="{ item }">
            <v-progress-circular    
              :size="20" 
              indeterminate
              color="grey"
              v-if="item.raw.comBox==9">
            </v-progress-circular>
            <v-chip
              class="ma-2"
              color="success"
              variant="outlined"
              v-else-if="item.raw.comBox==1"
            >
              <v-icon start icon="mdi-router-wireless"></v-icon>
              Online
            </v-chip>
            <v-chip
              class="ma-2"
              color="error"
              variant="outlined"
              v-else
            >
              <v-icon start icon="mdi-router-wireless-off"></v-icon>
              Offline
            </v-chip>            
          </template>
          <template v-slot:item.comPc="{ item }">
            <v-progress-circular    
              :size="20" 
              indeterminate
              color="grey"
              v-if="item.raw.comPc==9">
            </v-progress-circular>
            <v-chip
              class="ma-2"
              color="success"
              variant="outlined"
              v-else-if="item.raw.comPc==1"
            >
              <v-icon start icon="mdi-laptop"></v-icon>
              Online
            </v-chip>
            <v-chip
              class="ma-2"
              color="error"
              variant="outlined"
              v-else
            >
              <v-icon start icon="mdi-laptop-off"></v-icon>
              Offline
            </v-chip> 
            <v-chip
              class="ma-2"
              color="success"
              variant="outlined"
              v-if="item.raw.comFile==1"
            >
              <v-icon start icon="mdi-file-check-outline"></v-icon>
              HTTP 200
            </v-chip>
            <v-chip
              class="ma-2"
              color="error"
              variant="outlined"
              v-else
            >
              <v-icon start icon="mdi-file-remove"></v-icon>
              HTTP {{ item.raw.comFileTxt }}
            </v-chip>           
          </template>          
        </v-data-table>
  </v-container>
</template>
<script>
import axios from "axios";
import { useNavbarStore } from '@/stores';
import { useAlertStore } from '@/stores';
import { useAuthStore } from '@/stores';
export default {
  props : {
  },
  data () {
    return {
      title : 'Administration',
      tabs: [],
      tab : null,
      nav : [],          
      headers : [ {
            title: 'Hydroweb',
            key: 'name',
          },
          { title: 'Version', key: 'version' },
          { title: 'Router COM', key: 'comBox' },
          { title: 'PC COM', key: 'comPc' }],
        hydrowebs: []
    }
  },  
computed: {
  /*ready:  () => {
    this.init();
  },*/
},
mounted() {
  this.getNav();
  this.init();
},
methods: {
  getNav(){     
    const authStore = useAuthStore();
    this.nav = authStore.getNav('admin');
  },
  init(){
      const alertStore = useAlertStore();
      const navbarStore = useNavbarStore();
      navbarStore.setTitle(this.title);
      navbarStore.setTabs(this.tabs);
      navbarStore.setTab(null);
      navbarStore.setRight(null);
      navbarStore.setNav(this.nav);
      try {        
        var vm = this;
        var formData = new FormData();
        formData.append('action', 'getHydrowebs');
        formData.append('h', true);
        axios
        .post(vm.apiUrl+'/hyperviseur.php', formData)
        .then(function (response) {    
            if(response.data){
              for (let index = 0; index < response.data.length; index++) {
                const element = response.data[index];
                vm.hydrowebs.push({ 
                  id : element.id,
                  name : element.name,
                  version : element.version,
                  comBox : 9,
                  comPc : 9,
                  comFile : 0,
                  comFileTxt : 404,
                });
                var formDataEngineCheck = new FormData();
                formDataEngineCheck.append('action', 'checkOnline');
                formDataEngineCheck.append('url', element.hostapi);
                axios
                .post(vm.apiUrl+'/hyperviseur_engine.php', formDataEngineCheck)
                .then(function (responseEngineCheck) {
                  if(responseEngineCheck.data){ 
                    let hydrowebsIndexList = vm.hydrowebs.map(el => el.id);
                    let hydrowebIndex = hydrowebsIndexList.indexOf(element.id);
                    vm.hydrowebs[hydrowebIndex].comBox = responseEngineCheck.data.comBox;
                    vm.hydrowebs[hydrowebIndex].comPc = responseEngineCheck.data.comPc;
                    vm.hydrowebs[hydrowebIndex].comFile = responseEngineCheck.data.comFile;
                    vm.hydrowebs[hydrowebIndex].comFileTxt = responseEngineCheck.data.comFileTxt;
                    if(responseEngineCheck.data.comFileTxt == 200){
                      var formDataEngine = new FormData();
                      formDataEngine.append('action', 'getData');
                      formDataEngine.append('url', element.hostapi);
                      formDataEngine.append('id_hydroweb', element.id);
                      formDataEngine.append('v', element.version);
                      formDataEngine.append('h', true);
                      axios
                      .post(vm.apiUrl+'/hyperviseur_engine.php', formDataEngine)
                      .then(function (responseEngine) {    
                        if(responseEngine.data){
                          
                        }
                      }).catch(function (errorEngine) {   
                        alertStore.error('Erreur Axios 1: '+errorEngine+' '+element.name);
                      });
                    }else{
                      var formDataOffline = new FormData();
                      formDataOffline.append('action', 'comOffline');
                      formDataOffline.append('id_hydroweb', element.id);
                      formDataEngine.append('h', true);
                      axios
                      .post(vm.apiUrl+'/hyperviseur_engine.php', formDataOffline)
                      .then(function (responseOffline) {    
                        if(responseOffline.data){
                          
                        }
                      }).catch(function (errorOffline) {   
                        alertStore.error('Erreur Axios 2: '+errorOffline+' '+element.name);
                      });
                    }
                  }
                }).catch(function (errorEngineCheck) {   
                  //alertStore.error('Erreur Axios 3: '+errorEngineCheck+' '+element.name);
                });  
              }
            }          
        }).catch(function (error) {   
          alertStore.error('Erreur Axios 4: '+error+' '+element.name);
        });             
      } catch (error) {   
        alertStore.error(error);
      }               
  }
},
};
</script>  