<template>  
 
  <v-container fluid class="page-content"> 
    <v-row density="compact" >
      <Vidle v-show="false" @idle="onidle" :duration="30" />
    </v-row>
    <v-window v-model="windowsTab">
      <v-window-item class="px-2 py-2" style="width:95%;margin:0 auto;">   
        <v-row density="compact">
          <v-card class="mx-4 my-4 colVideo" v-for="(camera, indexCam) in camerasByFactory" :key="camera.id" :elevation="2"> 
            <v-layout>
              <v-app-bar density="compact" :color="camera.color">
                <v-progress-linear
                  :active="camera.loading"
                  :indeterminate="camera.loading"
                  color="deep-purple-accent-4"
                  absolute
                  bottom
                ></v-progress-linear>
                <template v-slot:prepend class="ml-0" v-if="(camera.api > 0) && camera.ptz">
                  <v-menu location="bottom">
                    <template v-slot:activator="{ props }">
                      <v-app-bar-nav-icon v-bind="props" size="x-small" @click="openConnection(camera.id)"></v-app-bar-nav-icon>
                    </template>

                    <v-list>
                      <v-list-item
                        v-for="(item, index) in camera.positions"
                        :key="index"
                        @click="goToPos(item.value,item.name)"
                      >
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                
                </template> 
              <v-app-bar-title class="text-subtitle-1">{{ camera.name }}</v-app-bar-title>

              <template v-slot:append>
                <v-btn icon="mdi-stop" size="x-small" @click="stopCamera(indexCam)"></v-btn>
                <v-btn icon="mdi-fullscreen" size="x-small" @click="fullCamera(camera.id)"></v-btn>
              </template>
            </v-app-bar>
            <v-main class="pt-12">
              <v-container class="pa-0 ma-0 h-100" h fluid>
                <v-img
                        :src="camera.src"
                        class="align-end"
                        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                        cover
                        v-if="camera.img"
                      >
                      </v-img>
                      <canvas :id="'1_'+camera.ref" v-else-if="camera.api == 2"/>
                      <iframe 
                        align="center"
                        :src="cameras[indexCam].video[1].link"
                        class="iframeVideo align-end"
                        :ref="'1_'+camera.ref"              
                         v-else-if="camera.api == 1">

                      </iframe>
                      <v-overlay
                        v-model="camera.video[1].overlay"
                        class="align-center justify-center"
                        contained
                        persistent
                        scroll-strategy="none"
                      >
                        <v-btn
                          color="grey-darken-2"
                          @click="getQualityLink(camera,1)"
                          icon="mdi-play" size="x-large"
                        >
                        </v-btn>
                      </v-overlay>
              </v-container>
            </v-main>
          </v-layout>
        </v-card>
      </v-row>
    </v-window-item>
      <v-window-item class="px-0 py-0">
        <v-row density="compact" v-if="cameras.length > 0 && camerasFour.length > 0">
            <v-card class="mx-2 my-2 colVideoFour" v-for="indexCam in camerasFour" :key="indexCam" :elevation="2"> 
            <v-layout>
              <v-app-bar density="compact" :color="cameras[indexCam].color">
                <template v-slot:prepend class="ml-0" v-if="(cameras[indexCam].api > 0) && cameras[indexCam].ptz">
                  <v-menu location="bottom">
                    <template v-slot:activator="{ props }">
                      <v-app-bar-nav-icon v-bind="props" size="x-small" @click="openConnection(cameras[indexCam].id)"></v-app-bar-nav-icon>
                    </template>

                    <v-list>
                      <v-list-item
                        v-for="(item, index) in cameras[indexCam].positions"
                        :key="index"
                        @click="goToPos(item.value,item.name)"
                      >
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                
                </template> 
              <v-app-bar-title class="text-subtitle-1">
                <v-select
                  :modelValue="cameras[indexCam].name"
                  :items="cameras.filter((_, index) => camerasFour.indexOf(index) == -1)"
                  density="compact"
                  item-title="name"
                  item-value="id"
                  variant="underlined"
                  @update:modelValue="changeCamera($event,indexCam)"
                ></v-select>
              </v-app-bar-title>

              <template v-slot:append>
                <v-btn icon="mdi-stop" size="x-small" @click="stopCamera(indexCam)"></v-btn>
                <v-btn icon="mdi-fullscreen" size="x-small" @click="fullCamera(cameras[indexCam].id)"></v-btn>
              </template>
            </v-app-bar>
            <v-main class="pt-12">
              <v-container class="pa-0 ma-0 h-100" h fluid>
            <v-img
                        :src="cameras[indexCam].src"
                        class="align-end"
                        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                        cover
                        v-if="cameras[indexCam].img"
                      >
                      </v-img>
                      <canvas :id="'2_'+cameras[indexCam].ref" v-else-if="cameras[indexCam].api == 2"/>
                      <iframe 
                        align="center"
                        :src="cameras[indexCam].video[2].link"
                        class="iframeVideo align-end"
                        :ref="'2_'+cameras[indexCam].ref" v-else-if="cameras[indexCam].api == 1">

                      </iframe>
                      <v-overlay
                        v-model="cameras[indexCam].video[2].overlay"
                        class="align-center justify-center"
                        contained
                        persistent
                        scroll-strategy="none"
                      >
                        <v-btn
                          color="grey-darken-2"
                          @click="getQualityLink(cameras[indexCam],2)"
                          icon="mdi-play" size="x-large"
                        >
                        </v-btn>
                      </v-overlay>
                      </v-container>
                      </v-main>
            </v-layout>
          </v-card>
        </v-row>
      </v-window-item>
      <v-window-item class="px-2 py-2">
        <v-row density="compact">
          <v-col class="colVideoOne" cols="12">
            <v-card class="mx-5 my-5 h-100" :elevation="2"> 
            <v-layout>
              <v-app-bar density="compact" :color="cameras[index_device_connected].color">
                <template v-slot:prepend class="ml-0" v-if="(cameras[index_device_connected].api > 0) && cameras[index_device_connected].ptz">
                  <v-menu location="bottom">
                    <template v-slot:activator="{ props }">
                      <v-app-bar-nav-icon v-bind="props" size="x-small"></v-app-bar-nav-icon>
                    </template>

                    <v-list>
                      <v-list-item
                        v-for="(item, index) in cameras[index_device_connected].positions"
                        :key="index"
                        @click="goToPos(item.value,item.name)"
                      >
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                
                </template>

              <v-app-bar-title class="text-subtitle-1">
                <v-select
                  :modelValue="cameras[index_device_connected].name"
                  :items="cameras"
                  density="compact"
                  item-title="name"
                  item-value="id"
                  variant="underlined"
                  @update:modelValue="openConnection"
                ></v-select>
              </v-app-bar-title>
              <template v-slot:append  >
                <v-btn icon="mdi-stop" size="x-small" @click="stopCamera(index_device_connected)"></v-btn>
                <v-btn  :icon="item.icon" @click="updateProfile(index)" size="x-small" v-if="cameras[index_device_connected].api > 0" v-for="(item, index) in cameras[index_device_connected].quality" :active="(index == cameras[index_device_connected].activeQuality)?true:false"></v-btn>
              </template>
            </v-app-bar>
            <v-main class="pt-12">
              <v-container class="containerCamOne pa-0" h fluid>
            <v-img
                        :src="cameras[index_device_connected].src"
                        class="align-end"
                        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                        cover
                        v-if="cameras[index_device_connected].img"
                      >
                      </v-img>
                      <canvas :id="'3_'+cameras[index_device_connected].ref" v-else-if="cameras[index_device_connected].api == 2"/>
                      <iframe 
                        id="iframeVideoOne"
                        align="center"
                        :src="cameras[index_device_connected].video[3].link"
                        class="iframeVideo align-end"
                        :ref="'3_'+cameras[index_device_connected].ref" v-else-if="cameras[index_device_connected].api == 1">              
                      </iframe>
                      <v-btn class="iframeVideoOneBtnPtz" @click="ptzClick"  v-if="cameras[index_device_connected].api > 0"></v-btn>
                      <v-overlay
                          v-model="cameras[index_device_connected].video[3].overlay"
                          class="align-center justify-center"
                          contained
                          persistent
                        scroll-strategy="none"
                        >
                          <v-btn
                            color="grey-darken-2"
                            @click="getQualityLink(cameras[index_device_connected],3)"
                            icon="mdi-play" size="x-large"
                          >
                          </v-btn>
                        </v-overlay>
                      </v-container>  
              <v-container class="containerCamOne pa-0" h fluid  v-if="cameras[index_device_connected].api > 0">                    
                      <v-slider
                          v-model="zoom"
                          append-icon="mdi-magnify-plus-outline"
                          prepend-icon="mdi-magnify-minus-outline"
                          :min="0"
                          :max="100"
                          :step="10"
                        ></v-slider>
                      </v-container>  
                      </v-main>
            </v-layout>
          </v-card>
          </v-col>
        </v-row>
      </v-window-item>
    </v-window>        
  </v-container>
</template>
<script>
const resizeObserver = new ResizeObserver(entries => {
   // We wrap it in requestAnimationFrame to avoid this error - ResizeObserver loop limit exceeded
   window.requestAnimationFrame(() => {
     if (!Array.isArray(entries) || !entries.length) {
       return;
     }
     // your code
   });
});
//var apiFileUrl = "api_domms.php";
import axios from "axios";
//import OnvifManager from "onvif-nvt";
import { useNavbarStore } from '@/stores';
import { useAuthStore } from '@/stores';
import { useAlertStore } from '@/stores';
import { proxyRefs } from "vue"
import Vidle from 'v-idle'
import { loadPlayer } from 'rtsp-relay/browser';
import { ref, onMounted } from 'vue'

export default {
  components: {
    Vidle,
  },
  data () {
    const srcs = {
        1: require('@/assets/icons/barrage.svg'),
        2: require('@/assets/icons/turbine.svg'),
        3: require('@/assets/icons/maisonVerte.svg'),
      }
    return {
      title : 'HydroCam',
      tabs: [{id : 0, value : 0, ref : 'tab1' ,hideMobile : 0, icon : 'mdi-grid'},
          {id : 1, value : 1,  ref : 'tab2' ,hideMobile : 0, icon : 'mdi-view-grid'},
          {id : 2, value : 2,  ref : 'tab3' ,hideMobile : 0, icon : 'mdi-square'}],
      tab : 0,
      right : 'tabs',
      search : true,
      nav : [],  
      button : true,  
      buttons: [{text : 'Lecture', color : 'green-darken-4', icon : 'mdi-play', value : 'playAll'},
          {text : 'Stop', color : 'red-darken-4', icon : 'mdi-stop', value : 'stopAll'}],
      factory: [],
      cameras: [{id: 0, 
                    com:  0, 
                    color : null,
                    name: null, 
                    flex:6, 
                    img : false, 
                    ref : null,
                    ip : null,
                    user : null,
                    password : null,
                    host : null,
                    port : null,
                    port_ws : null,
                    api :null,
                    ptz : null,
                    positions : [],
                    loading: false,
                    quality : { 3 : {link : null,
                                      width : '1300px',
                                      height : '740px'},
                                2 : {link : null,
                                      width : '650px',
                                      height : '500px'},
                                1 : {link : null,
                                      width : '300px',
                                      height : '200px'}
                              },
                    size:{width : '0',
                  height : '0'}  }],
      camerasFour: [0,1,2,3],
      camerasByFactory : [],
      cameraSelected : {size:{width :'0',height:'0'}},
      positions : [],
      ws : null,
      index_device_connected : 0,
      id_device_connected : 0,
      device_connected : false,
      ptz_moving : false,
      connecting : false,
      zoom: 0,
      zoomCalc : 0,
      zoomX : 0,
      zoomY : 0,
      eventWheel : null,
      zoomClick : false,
      activeSession : true
    }
  },
  mounted() {
    this.getNav();
    this.init();
    let vm = this; 

    window.addEventListener('keydown', function(ev) {
      if(vm.cameras[vm.index_device_connected].ptz){
        vm.ptzMove(ev);
      }
        //vm.myMethod(ev); // declared in your component methods
    });
    window.addEventListener('keyup', function(ev) {
      if(vm.cameras[vm.index_device_connected].ptz){
        vm.ptzStop();
      }
        //vm.myMethod(ev); // declared in your component methods
    });
    this.createWheelStopListener(window, function() {
      if(vm.cameras[vm.index_device_connected].ptz){
        var html = document.getElementsByTagName('html');
        var ev = vm.eventWheel;
        if(ev.target){
          if(ev.target.classList[ev.target.classList.length-1] == 'iframeVideoOneBtnPtz'){
            html[0].style.overflowY = 'hidden';
            if(vm.ptz_moving == false){
              vm.zoomX = ev.layerX;
              vm.zoomY = ev.layerY;
              if(ev.deltaY > 0){
                vm.zoom = (vm.zoom - 10 > 0)?vm.zoom - 10:0;
              }else{
                vm.zoom = (vm.zoom + 10 < 100)?vm.zoom + 10:100;
              }
            }
          }else{
            html[0].style.overflowY = 'scroll';
            vm.eventWheel = null;
          }
        }
      }
    });
    
  },
  watch : {
    zoom: function(val){    
      this.ptzClick(false);
      /*var posZ = {x: 0, y: 0, z: 0};
      var timeZ = 500;
      if(val == 0){
        posZ.z = -1;
        pos.z = -1;
        timeZ = 5000;
      }else if(val == 100){     
        posZ.z = 1; 
        pos.z = 1;
        timeZ = 5000;
      }else if(val < this.zoomCalc){
        posZ.z = -1;
        pos.z = -1;
        timeZ = (this.zoomCalc-val) * 50;
      }else if(val > this.zoomCalc){
        posZ.z = 1;
        pos.z = 1;
        timeZ = (val-this.zoomCalc) * 50;
      }else{
        posZ.z = 0;
        pos.z = 0;
        timeZ = 0;
      }
      if(timeZ > 0 && this.ptz_moving == false){
        this.ptz_moving = true;
        var vm = this;
        this.sendRequest('ptzMove', {
          'address': vm.cameraSelected.ip+':'+vm.cameraSelected.port,
          'speed'  : pos,
          'timeout': 30
        });
        setTimeout(function(){
                vm.ptzStop();
              }, timeZ);
      }*/
      this.zoomCalc = val
    },
    selectedFactory : function(){
      this.updateSelectedCamera();
    },
    windowsTab: function(val){
      /*var index = val + 1;
      for (const key in this.cameras) {
        if(this.cameras[key].video != ''){
          this.getQualityLink(this.cameras[key],index);
        }       
      }*/
    },
    btnClick : function(val){
      var index = this.windowsTab + 1;
      if(val == 'playAll'){
        if(this.windowsTab == 0){
          for (const key in this.cameras) {
            this.getQualityLink(this.cameras[key],index);
          }
        }else if(this.windowsTab == 1){
          for (let indexF = 0; indexF < this.camerasFour.length; indexF++) {
            this.getQualityLink(this.cameras[this.camerasFour[indexF]],index)
          }
        }else if(this.windowsTab == 2){
          this.getQualityLink(this.cameras[this.index_device_connected],index);
        }
      }else if(val == 'stopAll'){
        for (const key in this.cameras) {
          this.stopCamera(key);
        }
        const navbarStore = useNavbarStore();
        navbarStore.setButtonClick(false);
      }
    }
  },
  computed: {
    windowsTab:  () => {
      const navbarStore = useNavbarStore();
      return navbarStore.getTab();
    },
    selected:  () => {
      const navbarStore = useNavbarStore();
      return navbarStore.getSelected();
    },
    selectedFactory:  () => {
      const navbarStore = useNavbarStore();
      return navbarStore.getSearchSelected();
    },
    btnClick:  () => {
      const navbarStore = useNavbarStore();
      return navbarStore.getButtonClick();
    },
  },
  methods: {

    stopCamera(index){    
        for (const key in this.cameras[index].video) {
            this.cameras[index].video[key].link = '';
            this.cameras[index].video[key].overlay = true;                
        }
    },
    onidle(){
      this.activeSession = false;
      for (const key in this.cameras) {
        this.stopCamera(key);
      }
    },
    updateSelectedCamera(){   
      if(this.selectedFactory.length > 0){
        var listToReturn = [];
        for (const key in this.cameras) {
          if(this.selectedFactory.indexOf(this.cameras[key].factoryId) >= 0){
            listToReturn.push(this.cameras[key]);
          }
        }
        this.camerasByFactory = listToReturn;
      }else{
        this.camerasByFactory = this.cameras;
      }
      
    },
    getQualityLink(camera,index){
      var indexCamera = this.cameras.indexOf(camera);
      this.cameras[indexCamera].video[index].overlay = false;
      var link ='';
      if(camera.quality[index]){
        console.log(camera.quality);
          link = camera.quality[index].link;
        }else{
          var link = '';
          var min = 99;
          for(const key in camera.quality) {
            if(key < min){
              link = camera.quality[key].link;
            }
          }
        }
        this.cameras[indexCamera].video[index].link = link;
      if(this.cameras[indexCamera].api == 2){
        var id = index+'_'+this.cameras[indexCamera].ref;
        loadPlayer({
          url: link,
          canvas: document.getElementById(id),
        });
      }
    },
    fullCamera(id){
      this.openConnection(id);
      const navbarStore = useNavbarStore();
      navbarStore.setTab(2);
    },
    openConnection(id){
      var idListCamera = this.cameras.map(el => el.id);
      var indexCameras = idListCamera.indexOf(id);
      if(this.cameras[indexCameras].api == 2){
        if(this.connecting === false){
          if(indexCameras != this.index_device_connected){
            this.cameras[indexCameras].loading = true;
            this.index_device_connected = indexCameras;
            if(this.cameras[indexCameras].api == 2){
              //var hostWs = this.cameras[indexCameras].host+':'+this.cameras[indexCameras].port_ws;
              var hostWs = 'hikvision.helisa.io:3003';
              var host = this.cameras[indexCameras].ip+':'+this.cameras[indexCameras].port;
              var user = this.cameras[indexCameras].user;
              var password = this.cameras[indexCameras].password;
              var isPtz = this.cameras[indexCameras].ptz;
              var vm = this;
              if(this.device_connected === true){
                this.ws.onclose = function(event) {
                  console.log('WebSocket connection closed.');
                  vm.id_device_connected = 0;
                }.bind(this);
                this.initWebSocketConnection(hostWs,host,user,password,isPtz,id)
              }else{     
              this.initWebSocketConnection(hostWs,host,user,password,isPtz,id)
              }
            }else{      
              this.cameras[indexCameras].loading = false;
            }
          }
        }
      }else if(this.cameras[indexCameras].api == 1){
        try {        
          this.cameras[indexCameras].loading = true;
          var vm = this;
          var formData = new FormData();
          var url = this.cameras[indexCameras].host+':'+this.cameras[indexCameras].apihydrocheck;
          var ip = this.cameras[indexCameras].ip+':'+this.cameras[indexCameras].port;
          formData.append('action', 'getPositions');
          formData.append('url', url);
          formData.append('ip', ip);
          axios
          .post(this.apiUrl+'/hydrocam.php', formData)
          .then(function (response) { 
             vm.cameras[indexCameras].positions = response.data;          
             vm.cameras[indexCameras].loading = false;  
             vm.index_device_connected = indexCameras;
          }).catch(function (error) {  
            alertStore.error('Erreur Axios : '+error);
          });             
        } catch (error) {      
          alertStore.error(error);
        }  
      }else{
             this.cameras[indexCameras].loading = false;  
             this.index_device_connected = indexCameras;
      }
    },
    createWheelStopListener(element, callback, timeout) {
        var handle = null;
        var vm = this;
        var onScroll = function(ev) {
            vm.eventWheel = ev;
            if (handle) {
                clearTimeout(handle);
            }
            handle = setTimeout(callback, timeout || 200); // default 200 ms
        };
        element.addEventListener('wheel', onScroll);
        return function() {
            element.removeEventListener('wheel', onScroll);
            vm.eventWheel = null;
        };
    },
    getVideoUrl(camera){
      if(camera.brand == 1){
        return camera.miniature;
      }else{
        return camera.src;
      }
    },
    getNav(){     
      const authStore = useAuthStore();
      this.nav = authStore.getNav('hydrocam');
    },
    changeCamera(a,indexCam){
      var indexFour = this.camerasFour.indexOf(indexCam); 
      var idListCamera = this.cameras.map(el => el.id);
      var indexCameras = idListCamera.indexOf(a);
      this.camerasFour[indexFour] = indexCameras;
    },
    sendRequest(method,params){
      this.ws.send(JSON.stringify({
        'method': method,
        'params': params
      }));
    },
    connectDevice(host,user,password){
      this.sendRequest('connect', {
        'address': host,
        'user'   : user,
        'pass'   : password
      });
    },
    getPos(host){
      this.sendRequest('getPos', {
        'address':host
      });
    },
    getPosCallback(data) {
      var BreakException = {};
      var vm = this;
      this.positions = [];
      //console.log('POS CALLBACK');
      //console.log(data);
      if(vm.id_device_connected > 0){
        try { 
          if(vm.cameras[vm.index_device_connected].positions.length<=0){     
            for (let i = 0; i < data.result.data.GetPresetsResponse.Preset.length; i++) {
              var elt = data.result.data.GetPresetsResponse.Preset[i];
              //console.log(elt.Name.search("Preset"));
              if(elt.Name.search("Preset") < 0){
                  vm.cameras[vm.index_device_connected].positions.push({
                    value : elt.$.token,
                    name : elt.Name,
                  });         
                /*if(vm.cameraSelected && vm.cameraSelected.length > 0){
                  vm.cameraSelected.positions.push({
                    value : elt.$.token,
                    name : elt.Name,
                  });
                }*/
                /*var button = document.createElement('button');
                button.innerHTML = elt.Name;
                button.setAttribute('value',elt.$.token);
                button.type = 'button';
                button.className = 'btn-goto-pos';
                button.onclick = this.ptzGotoPos.bind(this);
                $('.position-ctl-box div').append(button)*/
              }
              if(i>=10) throw BreakException;
            }           
            this.cameras[this.index_device_connected].loading = false;
          }else{         
            this.cameras[this.index_device_connected].loading = false;
          }
        } catch (e) {
          this.cameras[this.index_device_connected].loading = false;
          if (e !== BreakException) throw e;
        }
      }else{    
        this.cameras[this.index_device_connected].loading = false;
      }

    },
    connectCallback(host,data){
      console.log(data);
      if(data.result) {
        this.showConnectedDeviceInfo(host, data.result);
        //this.el['div_pnl'].show();
        this.device_connected = true;	
        this.connecting = false;	     
        this.getPos(host);
      } else if(data.error) {
        //this.el['div_pnl'].hide();
        //this.showMessageModal('Error', 'Failed to connect to the device.' + data.error.toString());
        this.device_connected = false;
        this.id_device_connected = 0;
        this.connecting = false;
        this.cameras[this.index_device_connected].loading = false;
      }
    },
    showConnectedDeviceInfo(address, data){
      /*console.log(address);
      console.log(data);*/
    },
    initWebSocketConnection(hostWs,host,user,password,isPtz,idCameraConnected){
      this.connecting = true;
      var vm = this;
      this.ws = new WebSocket('wss://'+hostWs);
      this.ws.onopen = function() {
        console.log('WebSocket connection established.');
        this.connectDevice(host,user,password);
        vm.id_device_connected = idCameraConnected;
      }.bind(this);
      this.ws.onclose = function(event) {
        console.log('WebSocket connection closed.');
        vm.showMessageModal('Error', 'The WebSocket connection was closed. Check if the server.js is running.');
      }.bind(this);
      this.ws.onerror = function(error) {
        vm.showMessageModal('Error', 'Failed to establish a WebSocket connection. Check if the server.js is running.');
      }.bind(this);
      this.ws.onmessage = function(res) {
        var data = JSON.parse(res.data);
        var id = data.id;
        if(id === 'connect') {
          this.connectCallback(host,data);
        } else if(id === 'ptzMove' && isPtz) {
          this.ptzMoveCallback(data);
        } else if(id === 'ptzStop' && isPtz) {
          this.ptzStopCallback(data);
        } else if(id === 'ptzHome' && isPtz) {
          this.ptzHomeCallback(data);
        } else if(id === 'ptzPos' && isPtz) {
          this.ptzPosCallback(data);
        } else if(id === 'getPos' && isPtz) {
          this.getPosCallback(data);
        }
      }.bind(this);
    },
    ptzGotoPos(host,preset/*,event*/) {
      /*if(event.type === 'touchstart')  {
        return;
      }*/
      //console.log(this.device_connected+' '+this.ptz_moving)
      if((this.cameras[this.index_device_connected].api == 2 && this.device_connected === false) || this.ptz_moving === true || this.cameras[this.index_device_connected].ptz === false) {
        return;
      }
      //var preset = 2;
      /*preset = event.target.getAttribute('value');
      this.ptz_moving = true;*/
      this.sendRequest('ptzPos', {
        'address': host,
        'preset': preset,
      });
    },
    /*connect(){
        var hostWs = 'lamoulasse.ondulia.com:8880';
        var host = '10.0.44.100:3100';
        var user = 'hydrocam';
        var password = 'helisa09';
        var isPtz = true;
      if(this.device_connected === false){
        this.iitWebSocketConnection(hostWs,host,user,password,isPtz);
      }
    },*/
    goToPos(preset,name){
      const alertStore = useAlertStore();
      if(this.cameras[this.index_device_connected].api == 2){
        var host = this.cameras[this.index_device_connected].ip+':'+this.cameras[this.index_device_connected].port;
        this.ptzGotoPos(host,preset);
      }else if(this.cameras[this.index_device_connected].api == 1){
        try {        
          var formData = new FormData();
          var url = this.cameras[this.index_device_connected].host+':'+this.cameras[this.index_device_connected].apihydrocheck;
          var ip = this.cameras[this.index_device_connected].ip+':'+this.cameras[this.index_device_connected].port;
          name = name.replaceAll(' ','%20');
          formData.append('action', 'goToPos');
          formData.append('url', url);
          formData.append('ip', ip);
          formData.append('position', name);
          axios
          .post(this.apiUrl+'/hydrocam.php', formData)
          .then(function (response) { 
             //console.log(response.data)  
          }).catch(function (error) {  
            alertStore.error('Erreur Axios : '+error);
          });             
        } catch (error) {      
          alertStore.error(error);
        }
      }
    },
    ptzGotoHome(event) {
      event.preventDefault();
      event.stopPropagation();
      if(event.type === 'touchstart')  {
        return;
      }
      if(this.device_connected === false || this.ptz_moving === true) {
        return;
      }
      this.ptz_moving = true;
      this.sendRequest('ptzHome', {
        'address': this.cameras[this.index_device_connected].ip+':'+this.cameras[this.index_device_connected].port,
        'timeout': 30
      });
    },
    showMessageModal(title, message) {
      const alertStore = useAlertStore();
      alertStore.error(title+' : '+message);
    },
    ptzClick(event = false){
      const alertStore = useAlertStore();
      var vm = this;
      //console.log('MOUHAHAH');
      /*console.log(vm.sendRequest('getPos', {
            'address': vm.cameraSelected.ip+':'+vm.cameraSelected.port
          }));*/
      //console.log(this.device_connected+ ' MOVING '+this.ptz_moving+' PTZ '+this.cameras[this.index_device_connected].ptz);
      if((this.cameras[this.index_device_connected].api == 2 && this.device_connected === false) || this.ptz_moving === true || this.cameras[this.index_device_connected].ptz === false) {
        return;
      }
      
      this.ptz_moving = true;
      var x = 0;
        var y = 0;
        var width = 0;
        var height = 0;
        if(event !== false){
          var rect = event.currentTarget.getBoundingClientRect();
          x = event.layerX;
          y = event.layerY;
          width = rect.width;
          height = rect.height;
        }else{
          width = parseInt(this.cameras[this.index_device_connected].size.width);
          height = parseInt(this.cameras[this.index_device_connected].size.height);
          if(vm.eventWheel == null){
            if(this.cameras[this.index_device_connected].api == 1){
              x = 0;
              y = 0;
            }else{
              x = width/2;
              y = height/2;
            }
          }else{
            x = this.zoomX;
            y = this.zoomY;
          }
        }      
      if(this.cameras[this.index_device_connected].api == 1){
        try { 
          var deg100 = 35;       
          var formData = new FormData();
          var url = this.cameras[this.index_device_connected].host+':'+this.cameras[this.index_device_connected].apihydrocheck;
          var ip = this.cameras[this.index_device_connected].ip+':'+this.cameras[this.index_device_connected].port;
          var pos = {x: 0, y: 0, z: 0};

          if(this.zoom == this.zoomCalc){
            pos.z = 0;
          }else if(this.zoom == 0){
            pos.z = -9999;
          }else if(this.zoom == 100){     
            pos.z = 9999; 
          }else if(this.zoom < this.zoomCalc){
            pos.z = -((this.zoomCalc-this.zoom) * 9999 / 100);
          }else if(this.zoom > this.zoomCalc){
            pos.z = (this.zoom-this.zoomCalc) * 9999 / 100;
          }else{
            pos.z = 0;
          }
          if(this.zoom > 0){
            deg100 = deg100 / (this.zoom/10);
          }
          if(x > width/2){
            pos.x = (x-(width/2)) * deg100 / (width/2);
          }else if(x > 0 && x < width/2){
            pos.x = -(((width/2)-x) * deg100 / (width/2));
          }else{
            pos.x = 0;
          }
          if(y > height/2){
            pos.y = -((y-(height/2)) * deg100 / (height/2));
          }else if(y > 0 && y < height/2){
            pos.y = ((height/2)-y) * deg100 / (height/2);
          }else{
            pos.y = 0;
          }

          
          formData.append('action', 'ptzMove');
          formData.append('url', url);
          formData.append('ip', ip);
          formData.append('coord', JSON.stringify(pos));
          axios
          .post(this.apiUrl+'/hydrocam.php', formData)
          .then(function (response) { 
             vm.ptz_moving = false;
          }).catch(function (error) {  
             console.log(error)  
            alertStore.error('Erreur Axios : '+error);
          });             
        } catch (error) {    
          console.log(error)  

          alertStore.error(error);
        }
      }else if(this.cameras[this.index_device_connected].api == 2){
        var pos = {x: 0, y: 0, z: 0};
        var posX = {x: 0, y: 0, z: 0};
        var posY = {x: 0, y: 0, z: 0};
        var posZ = {x: 0, y: 0, z: 0};
        var timeY = 0;
        var timeX = 0;
        var timeZ = 0;      
        if(this.zoom == this.zoomCalc){
          posZ.z = 0;
          timeZ = 0;
        }else if(this.zoom == 0){
          posZ.z = -1;
          timeZ = 5000;
        }else if(this.zoom == 100){     
          posZ.z = 1; 
          timeZ = 5000;
        }else if(this.zoom < this.zoomCalc){
          posZ.z = -1;
          timeZ = (this.zoomCalc-this.zoom) * 50;
        }else if(this.zoom > this.zoomCalc){
          posZ.z = 1;
          timeZ = (this.zoom-this.zoomCalc) * 50;
        }else{
          posZ.z = 0;
          timeZ = 0;
        }

        //console.log(x+' '+width);
        if(x > width/2){
          //console.log("1");
          posX.x = 1;
          pos.x = 1;
          timeX = (x - (width/2));
        }else if(x < width/2){
          //console.log("2");
          posX.x = -1;
          pos.x = -1;
          timeX = ((width/2) - x);
        }else{
          //console.log("3");
          posX.x = 0;
          pos.x = 0;
          timeX = 0;
        }

        if(y > height/2){
          //console.log("4");
          posY.y = -1;
          pos.y = -1;
          timeY = (y - (height/2));
        }else if(y < height/2){
          //console.log("5");
          posY.y = 1;
          pos.y = 1;
          timeY = ((height/2) - y);
        }else{
          //console.log("6");
          posY.y = 0;
          pos.y = 0;
          timeY = 0;
        }      
        timeY = vm.applyCoeff(timeY,width);
        timeX = vm.applyCoeff(timeX,width);
        if(timeY > timeX){
          if(timeX > 0){
            vm.sendRequest('ptzMove', {
              'address': vm.cameras[vm.index_device_connected].ip+':'+vm.cameras[vm.index_device_connected].port,
              'speed'  : posX,
              'timeout': 30
            });
            setTimeout(function(){
              vm.ptzStop();
              vm.sendRequest('ptzMove', {
                'address': vm.cameras[vm.index_device_connected].ip+':'+vm.cameras[vm.index_device_connected].port,
                'speed'  : posY,
                'timeout': 30
              });
              setTimeout(function(){
                vm.ptzStop();
                if(timeZ > 0){
                  vm.sendRequest('ptzMove', {
                    'address': vm.cameras[vm.index_device_connected].ip+':'+vm.cameras[vm.index_device_connected].port,
                    'speed'  : posZ,
                    'timeout': 30
                  });
                  setTimeout(function(){
                    vm.ptzStop();
                  }, timeZ);
                }
              }, timeY); 
            }, timeX); 
          }else{
              vm.sendRequest('ptzMove', {
                'address': vm.cameras[vm.index_device_connected].ip+':'+vm.cameras[vm.index_device_connected].port,
                'speed'  : posY,
                'timeout': 30
              });
              setTimeout(function(){
                vm.ptzStop();
                if(timeZ > 0){
                  vm.sendRequest('ptzMove', {
                    'address': vm.cameras[vm.index_device_connected].ip+':'+vm.cameras[vm.index_device_connected].port,
                    'speed'  : posZ,
                    'timeout': 30
                  });
                  setTimeout(function(){
                    vm.ptzStop();
                  }, timeZ);
                }
              }, timeY); 
          }
        }else if(timeY < timeX){
          if(timeY > 0){
            vm.sendRequest('ptzMove', {
              'address': vm.cameras[vm.index_device_connected].ip+':'+vm.cameras[vm.index_device_connected].port,
              'speed'  : posY,
              'timeout': 30
            });
            setTimeout(function(){
              vm.ptzStop();
              vm.sendRequest('ptzMove', {
                'address': vm.cameras[vm.index_device_connected].ip+':'+vm.cameras[vm.index_device_connected].port,
                'speed'  : posX,
                'timeout': 30
              });
              setTimeout(function(){
                vm.ptzStop();
                if(timeZ > 0){
                  vm.sendRequest('ptzMove', {
                    'address': vm.cameras[vm.index_device_connected].ip+':'+vm.cameras[vm.index_device_connected].port,
                    'speed'  : posZ,
                    'timeout': 30
                  });
                  setTimeout(function(){
                    vm.ptzStop();
                  }, timeZ);
                }
              }, timeX); 
            }, timeY); 
          }else{
              vm.sendRequest('ptzMove', {
                'address': vm.cameras[vm.index_device_connected].ip+':'+vm.cameras[vm.index_device_connected].port,
                'speed'  : posY,
                'timeout': 30
              });
              setTimeout(function(){
                vm.ptzStop();
                if(timeZ > 0){
                  vm.sendRequest('ptzMove', {
                    'address': vm.cameras[vm.index_device_connected].ip+':'+vm.cameras[vm.index_device_connected].port,
                    'speed'  : posZ,
                    'timeout': 30
                  });
                  setTimeout(function(){
                    vm.ptzStop();
                  }, timeZ);
                }
              }, timeY); 
          }
        }else{
          vm.sendRequest('ptzMove', {
              'address': vm.cameras[vm.index_device_connected].ip+':'+vm.cameras[vm.index_device_connected].port,
              'speed'  : pos,
              'timeout': 30
          });
          setTimeout(function(){
                vm.ptzStop();
                if(timeZ > 0){
                  vm.sendRequest('ptzMove', {
                    'address': vm.cameras[vm.index_device_connected].ip+':'+vm.cameras[vm.index_device_connected].port,
                    'speed'  : posZ,
                    'timeout': 30
                  });
                  setTimeout(function(){
                    vm.ptzStop();
                  }, timeZ);
                }
              }, timeY); 
        }
      }
      if(event !== false){   
        event.preventDefault();
        event.stopPropagation();
      }
    },
    ptzMove(event) {
      if(this.device_connected === false || this.ptz_moving === true || this.cameras[this.index_device_connected].ptz === false) {
        return;
      }
      this.ptz_moving = true;
      var pos = {x: 0, y: 0, z: 0};
      var speed = 1.0;

      if(event.type === 'keydown') {
        var c = event.keyCode;
        var s = event.shiftKey;
        if(c === 38) { // Up
          pos.y = speed;
        } else if(c === 40) { // Down
          pos.y = 0 - speed;
        } else if(c === 37) { // Left
          pos.x = 0 - speed;
        } else if(c === 39) { // Right
          pos.x = speed;
        } else if((c === 107) || c === 187) { // Zoom in
          pos.z = speed;
        } else if(c === 109 || c === 189) { // Zoom out
          pos.z = 0 - speed;
        } else {
          return;
        }
      } else if(event.type.match(/^(mousedown|touchstart)$/)) {
        if(event.currentTarget.classList.contains('ptz-pad-box')) {
          var rect = event.currentTarget.getBoundingClientRect();
          var cx = event.clientX;
          var cy = event.clientY;
          if(event.type === 'touchstart') {
            if(event.targetTouches[0]) {
              cx = event.targetTouches[0].clientX;
              cy = event.targetTouches[0].clientY;
            } else if(event.changedTouches[0]) {
              cx = event.changedTouches[0].clientX;
              cy = event.changedTouches[0].clientY;
            }
          }
          var mx = cx - rect.left;
          var my = cy - rect.top;
          var w = rect.width;
          var h = rect.height;
          var r = Math.max(w, h) / 2;
          var x = mx - r;
          var y = r - my;
          var d = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2)) / r;
          var rad = Math.atan2(y, x);
          pos.x = d * Math.cos(rad);
          pos.y = d * Math.sin(rad);
        } else if(event.currentTarget.classList.contains('ptz-zom')) {
          if(event.currentTarget.classList.contains('ptz-zom-ot')) {
            pos.z = -1.0;
          } else if(event.currentTarget.classList.contains('ptz-zom-in')) {
            pos.z = 1.0;
          } else {
            return;
          }
        } else {
          return;
        }
      } else {
        return;
      }
      this.sendRequest('ptzMove', {
        'address': this.cameras[this.index_device_connected].ip+':'+this.cameras[this.index_device_connected].port,
        'speed'  : pos,
        'timeout': 30
      });
      event.preventDefault();
      event.stopPropagation();
    },
    zoomOut () {
      //console.log(this.zoomClick);
      if(this.zoomClick == false) {
        this.zoomClick = true;
        this.eventWheel = null;
        this.zoom = (this.zoom - 10 > 0)?this.zoom-10:0;
      }
    },
    zoomIn () {
      //console.log(this.zoomClick);
      if(this.zoomClick == false) {
        this.zoomClick = true;
        this.eventWheel = null;
        this.zoom = (this.zoom + 10 < 100)?this.zoom+10:100;
      }
    },
    ptzStop() {
      if(this.index_device_connected <= 0) {
        return;
      }
      this.sendRequest('ptzStop', {
        'address': this.cameras[this.index_device_connected].ip+':'+this.cameras[this.index_device_connected].port
      });
      this.ptz_moving = false;
      this.eventWheel = null;
    },

    ptzMoveCallback(data) {
      // do nothing
      //console.log(data);
    },

    ptzStopCallback(data) {
      // do nothing
    },

    ptzHomeCallback(data) {
      // do nothing
    },
    ptzPosCallback(data) {
      // do nothing
      //console.log('POS CALLBACK')
      this.ptz_moving = false;
    },
    applyCoeff(time,width){
      if (width > 1000){
        if(time > 1000){
          time = time * 0.4;
        }else if(time > 750){
          time = time * 0.5;
        }else if(time > 500){
          time = time * 0.6;
        }else if(time > 250){
          time = time * 0.8;
        }else if(time > 100){
          time = time * 1.5;
        }else if(time > 50){
          time = time * 3;
        }else{
          time = time * 5;
        }
      }else{   
        if(time > 1000){
          time = time * 0.4;
        }else if(time > 750){
          time = time * 0.5;
        }else if(time > 500){
          time = time * 0.6;
        }else if(time > 250){
          time = time * 0.8;
        }else if(time > 100){
          time = time * 1.5;
        }else if(time > 50){
          time = time * 3;
        }else{
          time = time * 5;
        }
      }
      return time;
    },
    /*hdProfile() {
      this.cameras[this.index_device_connected].video = this.cameras[this.index_device_connected].quality[3].link;
      this.cameras[this.index_device_connected].size.width = this.cameras[this.index_device_connected].quality[3].width;
      this.cameras[this.index_device_connected].size.height = this.cameras[this.index_device_connected].quality[3].height;
    },*/
    updateProfile(index) {
      this.cameras[this.index_device_connected].video = this.cameras[this.index_device_connected].quality[index].link;
      this.cameras[this.index_device_connected].size.width = this.cameras[this.index_device_connected].quality[index].width;
      this.cameras[this.index_device_connected].size.height = this.cameras[this.index_device_connected].quality[index].height;
      this.cameras[this.index_device_connected].activeQuality = index;
    },
    init(){
      const authStore = useAuthStore();
      const navbarStore = useNavbarStore();
      const alertStore = useAlertStore();
      navbarStore.setTitle(this.title);
      navbarStore.setTabs(this.tabs);
      navbarStore.setTab(0);
      navbarStore.setRight(this.right);
      navbarStore.setSearch(this.search);
      navbarStore.setNav(this.nav);
      navbarStore.setButton(this.button);
      navbarStore.setButtons(this.buttons);
      try {        
        var vm = this;
        var formData = new FormData();
        formData.append('action', 'getFactoryCamerasByUser');
        formData.append('id', authStore.user.id_user);
        formData.append('h', true);
        axios
        .post(this.apiUrl+'/hydrocam.php', formData)
        .then(function (response) { 
            if(response.data){
              //var x = 1;
              vm.cameras = [];
              for (let i = 0; i < response.data.length; i++) {
                var color = 'white'
                if(response.data[i].com == 0){
                  color = 'red-lighten-4'
                }                 
                  var idListFactory = vm.factory.map(el => el.id);
                  if(idListFactory.indexOf(response.data[i].factoryId) == -1){
                    vm.factory.push({
                      id : response.data[i].factoryId,
                      name : response.data[i].factory
                    });
                  }
                  vm.cameras.push({
                    id: response.data[i].id, 
                    factoryId: response.data[i].factoryId, 
                    com:  response.data[i].com, 
                    color : color,
                    name: response.data[i].factory+' - '+response.data[i].name, 
                    flex:6, 
                    img : false, 
                    ref : 'iframeVideo_'+response.data[i].id,
                    ip : response.data[i].ip,
                    user : response.data[i].user,
                    password : response.data[i].password,
                    host : response.data[i].host,
                    apihydrocheck : response.data[i].apihydrocheck,
                    port : response.data[i].port,
                    port_ws : response.data[i].port_ws,
                    api : response.data[i].api,
                    ptz : response.data[i].ptz,
                    positions : [],
                    loading : false,
                    video : {'1' : {link : '', overlay : true},2 : {link : '', overlay : true},3 : {link : '', overlay : true}},
                    activeQuality : response.data[i].activeQuality,
                    quality : response.data[i].quality,
                    /*quality : { hd : {link : response.data[i].cameras[j].video,
                                      width : '1300px',
                                      height : '740px'},
                                sd : {link : response.data[i].cameras[j].medium,
                                      width : '650px',
                                      height : '500px'},
                                mini : {link : response.data[i].cameras[j].miniature,
                                      width : '300px',
                                      height : '200px'}
                              },*/
                    size:{width : response.data[i].width,
                  height : response.data[i].height}      
                  });
              }
              navbarStore.setSearchSelectList(vm.factory);
              vm.camerasByFactory = vm.cameras;
              vm.loadingData = false;
              //console.log(vm.factory);
            }          
        }).catch(function (error) {  
          alertStore.error('Erreur Axios : '+error);
        });             
      } catch (error) {      
        alertStore.error(error);
      }  
    },
  }
}
</script>
<style>
.v-select .v-field__input{
  padding-top : 1.5em !important;
}
</style>
<style scoped>
.v-card {
  display: flex !important;
  flex-direction: column;
}

.box-cam-content {
  flex-grow: 1;
  overflow: auto;
}
.colVideo{
  width : 480px;
  min-height : 352px;
}
.colVideoFour{
  width : 800px;
  min-height : 545px;
}
.colVideoOne{
  min-height : 1200px;
}
.containerCamOne{
  width: v-bind('cameras[index_device_connected].size.width');
  height: v-bind('cameras[index_device_connected].size.height') !important;
  overflow: hidden;
  margin: 2em auto !important;
  position : relative;
}
.iframeVideo{
  overflow:hidden;
  width : 100%;
  height : 100%;
  border : 0px;
}
.iframeVideo html{
  overflow:hidden;
}
.iframeVideoOneBtnPtz {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    opacity: 0;
    margin: 0.5em;
    padding: 0;
}
.noScroll{
  overflow-y:hidden !important;
}

.page-content{
  padding: 1em 0em 1em 5em !important;
}
</style>