import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from '@/App.vue'
import router from '@/router'
import vuetify from '@/plugins/vuetify'
import { loadFonts } from '@/plugins/webfontloader'
import VueApexCharts from "vue3-apexcharts"
import piniaPluginPersistedState from "pinia-plugin-persistedstate"
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import Highcharts from "highcharts"
import HighchartsVue from 'highcharts-vue'
loadFonts()
const pinia = createPinia()
pinia.use(piniaPluginPersistedState)


const app = createApp(App);
if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
  app.config.globalProperties.apiUrl = 'http://localhost/hyperviseur-api'
} else {
  app.config.globalProperties.apiUrl = 'https://api.helisa.io'
}
app.use(router)
  .use(vuetify)
  .use(pinia)
  .use(Highcharts)
  .use(HighchartsVue, { Highcharts })
  .use(VueApexCharts)
  .component('VueDatePicker', VueDatePicker)
app.mount('#app')

