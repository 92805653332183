import { defineStore } from 'pinia';

export const useNavbarStore = defineStore('navbar',{
    id: 'navbar',
    state: () => ({
        title: null,
        nav : false,
        tab : 0,
        tabs : [],
        right : null,
        search : false,
        searchSelected : [],
        searchSelectList : [],
        statsSelectList : [],
        navDrawer : null,
        statsSelected : null,
        button : false,
        buttons : [],
        buttonClick : false,
    }),
    actions: {
        setTitle(title) {
            this.title = title;
        },
        setNav(nav) {
            this.nav = nav;
        },
        setTabs(tabs) {
            this.tabs = tabs;
        },
        setTab(tab) {
            this.tab = tab;
        },
        setButton(button) {
            this.button = button;
        },
        setButtons(buttons) {
            this.buttons = buttons;
        },
        setButtonClick(value) {
            this.buttonClick = value;
        },
        setRight(right) {
            this.right = right;
        },
        setSearch(search) {
            this.search = search;
        },
        setSearchSelectList(searchSelectList) {
            this.searchSelectList = searchSelectList;
        }, 
        setSearchSelected(searchSelected) {
            this.searchSelected = searchSelected;
        }, 
        setStatsSelectList(statsSelectList) {
            this.statsSelectList = statsSelectList;
        }, 
        setStatsSelected(statsSelected) {
            this.statsSelected = statsSelected;
        },  
        setNavDrawer(navDrawer) {
            this.navDrawer = navDrawer;
        },       
        getTitle() {
            return this.title;
        },
        getNav() {
            return this.nav;
        },
        getTabs() {
            return this.tabs;
        },
        getButton() {
            return this.button;
        },
        getButtons() {
            return this.buttons;
        },
        getButtonClick() {
            return this.buttonClick;
        },
        getTab() {
            return this.tab;
        },
        getRight() {
            return this.right;
        },
        getSearch() {
            return this.search;
        },
        getSearchSelectList(){
            return this.searchSelectList;
        },
        getSearchSelected(){
            return this.searchSelected;
        },
        getStatsSelectList(){
            return this.statsSelectList;
        },
        getStatsSelected(){
            return this.statsSelected;
        },
        getNavDrawer(){
            return this.navDrawer;
        }
    }
});