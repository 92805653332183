<template>
  <v-container fluid class="page-content">
    <div v-if="statsValue == 1">       
      <v-row density="compact">
        <v-col lg="6" md="6" sm="12" xs="12">
          <v-data-table
            :headers="headers"
            :items="hydrowebs"
            class="elevation-1"
            :items-per-page="40"
          >

          </v-data-table>
        </v-col>
        <v-col lg="6" md="6" sm="12" xs="12">
          <v-data-table
            :headers="headers"
            :items="hydrowebs"
            class="elevation-1"
            :items-per-page="40"
          >

          </v-data-table>
        </v-col>
      </v-row>
    </div>
    <div v-else-if="statsValue == 2">
      <v-row density="compact">
        <v-col cols="12">

        </v-col>
      </v-row>     
    </div>            
  </v-container>
</template>
<script>
const resizeObserver = new ResizeObserver(entries => {
   // We wrap it in requestAnimationFrame to avoid this error - ResizeObserver loop limit exceeded
   window.requestAnimationFrame(() => {
     if (!Array.isArray(entries) || !entries.length) {
       return;
     }
     // your code
   });
});
//var apiFileUrl = "api_domms.php";
//import axios from "axios";
import { useNavbarStore } from '@/stores';
import { useAuthStore } from '@/stores';
export default {
  data () {
    const srcs = {
        1: require('@/assets/icons/barrage.svg'),
        2: require('@/assets/icons/turbine.svg'),
        3: require('@/assets/icons/maisonVerte.svg'),
      }
    return {
      title : 'Hydrostats',
      tabs: [],
      tab : 0,
      right : 'statsSelect',
      nav : [],
      centralesSearch: [
          { id: 1, name: 'Ouverture Vanne RD/RG', type : 'table', icon: 'mdi-table-large' },
          { id: 2, name: 'Stats 2', icon: 'mdi-chart-bar' },
        ],
      headers : [ {
            title: 'Jours',
            key: 'day',
            align : 'center'
          },
          { title: 'Ouverture', key: 'open', align : 'center' },
          { title: 'Fermeture', key: 'close', align : 'center' }],
      stats: []
    }
  },
  computed: {
    statsValue:  () => {
      const navbarStore = useNavbarStore();
      console.log(navbarStore.getStatsSelected());
      return navbarStore.getStatsSelected();
    },
  },
  mounted() {
    this.getNav();
    this.init();
  },
  methods: {
    getNav(){     
      const authStore = useAuthStore();
      this.nav = authStore.getNav('hydrostats');
    },
    init(){
      const navbarStore = useNavbarStore();
      navbarStore.setTitle(this.title);
      navbarStore.setTabs(this.tabs);
      navbarStore.setTab(this.tab);
      navbarStore.setRight(this.right);
      navbarStore.setNav(this.nav);
      navbarStore.setStatsSelectList(this.centralesSearch);
      navbarStore.setStatsSelected(1);
    },
  }
}
</script>  