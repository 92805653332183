import { createRouter, createWebHashHistory } from 'vue-router'
import { useAuthStore, useAlertStore } from '@/stores';
import hyperviseurRoutes from './hyperviseur.routes';
import hydrocamRoutes from './hydrocam.routes';
import hydrostatsRoutes from './hydrostats.routes';
import adminRoutes from './admin.routes';
import axios from "axios";

const router = createRouter({
  history: createWebHashHistory(),
  linkActiveClass: 'active',
  routes:[
    {
      path: '/',
      component: () => import('../layouts/minimal'),
      children: [
        { path: '', component: () => import('../views/login') }
      ]
    },
    {
      path: '/t/:id/:page',
      component: () => import('../layouts/minimal'),
      children: [
        { path: '', component: () => import('../views/login') }
      ]
    },
    { ...hyperviseurRoutes },
    { ...hydrocamRoutes },
    { ...hydrostatsRoutes },
    { ...adminRoutes },
    // catch all redirect to home page
    { 
      path: '/noip',
      component: () => import('../views/noip'),
      meta: { requiresAuth: false }
    },
    { 
      path: '/404',
      component: () => import('../views/404'),
      meta: { requiresAuth: false }
    },
    { 
      path: '/:pathMatch(.*)*',
      component: () => import('../views/404'),
      meta: { requiresAuth: false }
    }
  ]
})

export default router
router.beforeEach((to) => {
  // clear alert on route change
  const alertStore = useAlertStore();
  alertStore.clear();
  const auth = useAuthStore()
  auth.authorizedIp(to.path);
  auth.authorizedRoute(to.path)
  if (to.meta.requiresAuth && !auth.isLoggedIn()){
    return '/'
  }
})
