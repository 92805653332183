import { Home, Production, Courbes } from '@/views/hyperviseur';

export default {
    path: '/hyperviseur',
    component: () => import('../layouts/default'),
    children: [
        { path: '', component: Home },
        { path: 'production', component: Production },
        { path: 'courbes', component: Courbes }
    ],
    meta: { requiresAuth: true }
};