import { defineStore } from 'pinia'
import { router } from '@/router';
import { useAlertStore } from '@/stores';
import axios from "axios"
export const useAuthStore = defineStore('auth',{
  id : 'auth',
  state: () => ({
      // initialize state from local storage to enable user to stay logged in
      user: null,
      LoggedIn : false,
      url : false,
      ip : '0.0.0.0',
  }),
  actions: {
      async login(url, username, password, t, page) {
        const alertStore = useAlertStore();
        let href = 'hyperviseur';
        try {
            //const user = await fetchWrapper.post(url, { username, password });               
            var vm = this;
            var formDataIp = new FormData();           
            var formDataAccess = new FormData();
            formDataIp.append('action', 'getIpaddress');
            formDataIp.append('h', true);
            axios.post(url+'/account.php', formDataIp).then(({ data }) => {
                vm.ip = data;
                formDataAccess.append('ip_address', data);                
                var formData = new FormData();
                formData.append('action', 'authentification');
                formData.append('login', username);
                formData.append('password', password);
                formData.append('h', true);
                axios
                .post(url+'/account.php', formData)
                .then(function (response) { 
                    formDataAccess.append('action', 'addAccess');  
                    formDataAccess.append('h', true);        
                    if(response.data.auth){
                        vm.user = response.data;                    
                        vm.user.t = t;
                        if(page){
                            vm.user.defaultPage = page;
                        }
                        formDataAccess.append('id_user', response.data.id_user);
                        axios
                        .post(url+'/account.php', formDataAccess)
                        .then(function (responseAccess) {
                                console.log(responseAccess.data.message);
                        });   
                        href = vm.user.defaultPage;
                        vm.LoggedIn = true;    
                        vm.url = url;                                    
                        window.location.href = '#/'+href+'/'
                    }else{             
                        alertStore.error('Identifiant ou Mot de passe incorrect');
                        vm.logout();
                    }                               
                })           
                .catch(function (error) {
                        alertStore.error('Erreur Axios : '+error);
                });     
            });            
        } catch (error) {
            alertStore.error(error);
        }
    },
    logout() {
        this.user = null;
        this.LoggedIn = false;
        this.url = false;
        this.ip = '0.0.0.0';
    },
    getUserId() {
        return this.user.id_user;
    },
    getUser() {
        return this.user;
    },
    isAdmin(){       
        if(this.user.role == 1){
            return true;
        }
        return false;
    },
    getNav(module){
        return this.user.pages[module];
    },
    isLoggedIn(){
        return this.LoggedIn;
    },
    getIp() {
        return this.ip;
    },
    authorizedIp(path){  
        //const user = await fetchWrapper.post(url, { username, password });   
        if(path != '/noip/'){
            if(this.url != false){
                var formData = new FormData();
                formData.append('action', 'authorizedIp');
                formData.append('ip', this.ip);
                formData.append('h', true);
                 axios
                .post(this.url+'/account.php', formData)
                .then(function (response) {    
                    if(!response.data.auth){
                        window.location.href = '#/noip/'
                    }
                });
            }else{
                this.logout();
            }
   
        }      
    },
    authorizedRoute(path){
        const alertStore = useAlertStore();
        var vm = this;
        if(path == '/' || path == '' || path == '/noip/' || path == '/404/') return true;
        if(this.url != false){
            try {         
                //const user = await fetchWrapper.post(url, { username, password });    
                var formData = new FormData();
                formData.append('action', 'authorizedRoute');
                formData.append('id', this.user.id_user);
                formData.append('route', path);
                formData.append('h', true);
                axios
                .post(this.url+'/account.php', formData)
                .then(function (response) {    
                    if(!response.data.auth){                
                        window.location.href = '#/404/'
                    }
                })
                .catch(function (error) {           
                    alertStore.error('Erreur Axios : '+error);
                });             
            } catch (error) {     
                alertStore.error(error);
            } 
        }else{
            this.logout();          
        }       
    }
  },
  persist: {
    storage: sessionStorage, // data in sessionStorage is cleared when the page session ends.
  },
});